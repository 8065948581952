export const defaultBgColor = '#f2c75c';
export const linearBgTopToBottom =
  'linear-gradient(0deg, rgb(250, 250, 250) 97%, #f2c75c 99%)';
export const linearBgBottomToTop = 'linear-gradient(rgb(250, 250, 250) 80%, #f2c75c 90%)';

export const getBgStyle = (linearBg, isDesktop) => ({
  width: '100%',
  background: isDesktop ? linearBg : defaultBgColor,
});

export const commonShadowLayout = (theme) => ({
  background: `${theme.palette.primary.dark} no-repeat right -4px`,
  borderRadius: 6,
});
