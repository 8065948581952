import React from 'react';
import { getLegalByLocale } from '../utils/contentful';
import { graphql } from 'gatsby';
import { useLocaleContext } from '../Providers/LocaleProvider';
import { createPrivacyPolicy } from '../utils/markdown';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SEO from '../components/SEO/SEO';
import { getBgStyle, linearBgTopToBottom } from '../utils/styles';
import { desktopLimit } from '../constants/media';

export const legalQuery = graphql`
  {
    allContentfulLegal {
      nodes {
        legalTitle
        legalContent {
          raw
        }
        node_locale
      }
    }
  }
`;

const PrivacyPolicy = ({ data }) => {
  const { locale } = useLocaleContext();
  const {
    allContentfulLegal: { nodes: legalSection },
  } = data;
  const isDesktop = useMediaQuery(desktopLimit);
  const legalLocaled = getLegalByLocale(legalSection, locale);

  return (
    <>
      <SEO pageId={'privacyPolicy'} />
      <section style={getBgStyle(linearBgTopToBottom, isDesktop)}>
        <div
          style={{
            margin: 'auto',
            maxWidth: '1200px',
            padding: '8em 3em',
            color: '#000028',
            textOverflow: 'auto',
            '& > a': {
              display: 'block',
            },
          }}
        >
          <h4 id={'privacy-policy'}>{legalLocaled.legalTitle.toUpperCase()}</h4>
          {documentToReactComponents(
            JSON.parse(legalLocaled?.legalContent),
            createPrivacyPolicy(isDesktop),
          )}
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
