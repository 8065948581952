import { Typography } from '@material-ui/core';
import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const headingStyle = (isDesktop) => ({ marginBottom: 20, fontSize: isDesktop ? '1.3rem' : '1rem' });
const paragraphStyle = { fontWeight: 300 };

const getCommonItemConfig = (isDesktop) => ({
  [BLOCKS.HEADING_1]: (node, children) => convertHeadingMarkDownNodes(node, children, isDesktop),
  [BLOCKS.HEADING_2]: (node, children) => convertHeadingMarkDownNodes(node, children, isDesktop),
  [BLOCKS.HEADING_3]: (node, children) => convertHeadingMarkDownNodes(node, children, isDesktop),
  [BLOCKS.HEADING_4]: (node, children) => convertHeadingMarkDownNodes(node, children, isDesktop),
});
export const convertMarkDownNodes = (node, children) => {
  return (
    children && (
      <Typography style={paragraphStyle} variant="body1">
        {children}
      </Typography>
    )
  );
};

export const convertHeadingMarkDownNodes = (node, children, isDesktop) => {
  return (
    children && (
      <Typography style={headingStyle(isDesktop)} variant="h4">
        {children}
      </Typography>
    )
  );
};

export const createDescriptionOptions = (isDesktop) => ({
  renderMark: {
    [MARKS.BOLD]: (text) => {
      return <strong>{text}</strong>;
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => convertMarkDownNodes(node, children),
    ...getCommonItemConfig(isDesktop),
  },
});

export const createDetailsItemOptions = () => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => convertMarkDownNodes(node, children),
  },
});

export const createPrivacyPolicy = (isDesktop) => ({
  renderMark: {
    [MARKS.BOLD]: (text) => {
      return <strong>{text}</strong>;
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        children && (
          <Typography style={{ fontWeight: 300, marginTop: 10 }} variant="body1">
            {children}
          </Typography>
        )
      );
    },
    ...getCommonItemConfig(isDesktop),
  },
});
